<template>
  <div class="account">
    <logo text="Account" />
    <b-card>
      <b-card-body>
        <router-link to="/account/0xa32S0m3Addr355h3re/profile">Profile</router-link><br />
        <router-link to="/account/0xa32S0m3Addr355h3re/collection">Collection</router-link><br />
        <router-link to="/account/0xa32S0m3Addr355h3re/settings">Settings</router-link>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
  },
  data(){
    return {
    }
  }
}
</script>
