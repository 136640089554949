<template>
  <div class="collection">
    <logo text="Collection" />
    <b-card>
      <b-card-body>
        hello
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
  },
  data(){
    return {
    }
  }
}
</script>
